import { ALL_FIATS } from "helpers/fiatOptions";
import { fromWei, toWei } from "web3-utils";
import type { CurrencyInterface, PricesInterface } from "helpers/types";

export const toLocaleStringWithCurrencyNoDecimals = (
  value: number,
  currencyCode: string,
  type?: "noCode"
) => {
  if (!currencyCode) return "";

  if (ALL_FIATS.includes(currencyCode)) {
    return type
      ? parseFloat(String(value)).toLocaleString("en-US", {
          style: "currency",
          currency: currencyCode,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : parseFloat(String(value))
          .toLocaleString("en-US", {
            style: "currency",
            currency: currencyCode,
            currencyDisplay: "code",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          .replace(currencyCode, "") +
          " " +
          currencyCode;
  }

  const number = parseFloat(String(value)).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return `${number}${" "}${currencyCode}`;
};

export const toLocaleStringWithCurrency = (
  value: number | string,
  currencyCode: string,
  currencies?: CurrencyInterface[] | null
) => {
  if (!currencyCode) return "";

  if (currencyCode === "USD" || currencyCode === "EUR") {
    const number = parseFloat(String(value)).toLocaleString("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (number.includes("NaN")) {
      return number.replace("NaN", "0.00");
    }

    return number;
  }

  const precision =
    currencies?.find(({ name }) => name === currencyCode)?.precision || 8;

  const number = parseFloat(String(value)).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: precision,
  });

  if (Number(value) > 0 && number === "0.00") {
    return `${
      precision >= 7 ? "> 0.0000001" : `> ${(0).toFixed(precision - 1)}1`
    }${" "}${currencyCode}`;
  }
  return `${number}${" "}${currencyCode}`;
};

export const normolizeDecimalsWithNoCode = (
  value: number | string,
  currencyCode: string,
  currencies?: CurrencyInterface[] | null,
  type?: "withNoSymbols"
) => {
  if (!currencyCode) return "";

  if (currencyCode === "USD" || currencyCode === "EUR") {
    if (type === "withNoSymbols") {
      return parseFloat(String(value))
        .toLocaleString("en-US", {
          style: "currency",
          currency: currencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replaceAll("$", "")
        .replaceAll("€", "");
    }
    return parseFloat(String(value)).toLocaleString("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const precision =
    currencies?.find(({ name }) => name === currencyCode)?.precision || 8;

  return parseFloat(String(value)).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: precision,
  });
};

export const normolizeDecimalsForInputs = (
  value: number | string,
  currencyCode: string,
  currencies?: CurrencyInterface[] | null
) => {
  if (!currencyCode) return "";

  if (currencyCode === "USD" || currencyCode === "EUR") {
    return parseFloat(String(value))
      .toLocaleString("en-US", {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replaceAll(",", "")
      .replaceAll("$", "")
      .replaceAll("€", "");
  }

  const precision =
    currencies?.find(({ name }) => name === currencyCode)?.precision || 8;

  return isFinite(Number(value))
    ? parseFloat(String(value))
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: precision,
        })
        .replaceAll(",", "")
    : "0";
};

export const isValidPassword = (password: string) => {
  return (
    password.length >= 8 &&
    password.toLowerCase() !== password &&
    password.toUpperCase() !== password &&
    /\d/.test(password) &&
    /[ `!@#$%^&*()_/+\-=\]{};':"\\|,.<>?~]/.test(password)
  );
};

export const normolizeCurrenciesDecimals = (
  value: number | string,
  currencyCode: string,
  currencies?: CurrencyInterface[] | null,
  type?: "forView"
) => {
  if (!currencyCode) return "";

  if (isNaN(Number(value)) || !isFinite(Number(value))) {
    return "0";
  }
  if (currencyCode === "USD" || currencyCode === "EUR") {
    return Number(value).toFixed(2);
  }

  const precision =
    currencies?.find(({ name }) => name === currencyCode)?.precision || 8;

  let result =
    parseFloat(String(value)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: precision,
    }) || "0.00";

  const fixedResult = (
    Number(result.replaceAll(",", "")) -
    Math.sign(Number(String(value).replaceAll(",", ""))) *
      Math.pow(0.1, precision)
  ).toFixed(precision);

  result =
    Math.abs(Number(result.replaceAll(",", ""))) <= Math.abs(Number(value))
      ? type === "forView"
        ? result
        : result.replaceAll(",", "")
      : type === "forView"
      ? Number(fixedResult) < 0
        ? fixedResult
        : fixedResult.replace("-", "")
      : fixedResult;

  return result;
};

export const roundDecimalsNumber = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const format = (value: string, pattern: string) => {
  let i = 0;
  return pattern.replace(/9/g, () => value[i++]);
};

export const convertCurrencyValue = (
  fromCurrency: string,
  toCurrency: string,
  amount: number,
  prices: PricesInterface
): number => {
  if (fromCurrency === toCurrency) {
    return amount;
  }
  const exchangeRate = prices[`${fromCurrency}/${toCurrency}`]?.close;

  if (exchangeRate) {
    return amount * Number(exchangeRate);
  }
  const reverseExchangeRate = prices[`${toCurrency}/${fromCurrency}`]?.close;

  if (reverseExchangeRate) {
    return amount / Number(reverseExchangeRate);
  }
  return 0;
};

export const getCurrenciesPriority = (
  currencies: CurrencyInterface[]
): { [k: string]: number } =>
  currencies.reduce((acc: { [k: string]: number }, el: CurrencyInterface) => {
    acc[el.name] = el.priority;
    return acc;
  }, {});

export const convertWeiToEth = (value: string) => {
  if (value) {
    const convertedValue = fromWei(value, "ether");

    if (convertedValue === "0.") {
      return "0";
    }
  }
  return "";
};

export const convertEthToWei = (value: string) => {
  if (value) {
    const convertedValue = toWei(value, "ether");

    if (convertedValue === "0.") {
      return "0";
    }
  }
  return "";
};
