import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSDK } from "@metamask/sdk-react";
import { MainButton, ModalButton } from "components/Buttons";
import {
  TransactionGroup,
  InfoLine,
  ModalError,
  ModalErrorStatus,
  ModalErrorInfo,
} from "../styled";
import { AppText } from "components";
import { ReadInfo, CurrencyInput, RangeInput } from "components/Inputs";
import { colors } from "helpers/consts";
import { convertWeiToEth } from "helpers/funcs";
import type { CurrencyInterface } from "helpers/types";

import { ReactComponent as MetaMaskIcon } from "assets/icons/metamask.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";

const exceedAmount = "ERROR_NO_AMOUNT";
const metamaskChainError = "ERROR_METAMASK_CHAIN";

interface DepositMetaMaskTabProps {
  walletAddress?: string;
  currencies: CurrencyInterface[] | null;
}

const DepositMetaMaskTab: FC<DepositMetaMaskTabProps> = ({
  walletAddress,
  currencies,
}) => {
  const { t } = useTranslation();
  const { account, balance, provider, sdk, chainId } = useSDK();

  const [value, setValue] = useState<string>("");
  const [hasError, setHasError] = useState<string | null>(null);

  const convertedToEthBalance = useMemo<number>(() => {
    if (balance) {
      return Number(convertWeiToEth(balance));
    }
    return 0;
  }, [balance]);

  const checkIfError = (value: number, chainId?: string) => {
    if (hasError) {
      setHasError(null);
    }
    if (chainId && chainId !== "0x1") {
      setHasError(t(metamaskChainError));
      return;
    }
    if (!value) {
      return;
    }
    if (Number(value) > Number(convertedToEthBalance)) {
      setHasError(t(exceedAmount));
    }
  };

  const handleChangeValue = (value: string) => {
    const correctInvalidValue = value[0] === "." ? `0${value}` : value;

    setValue(correctInvalidValue);
    checkIfError(Number(value), chainId);
  };

  const sendMetamask = useCallback(() => {
    if (account && provider) {
      provider
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: account,
              to: walletAddress,
              value: "001",
            },
          ],
        })
        .then((txHash) => console.log(txHash))
        .catch((error) => console.error(error));
    }
  }, [account, provider, walletAddress]);

  const connect = useCallback(async () => {
    try {
      await sdk?.connect();
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  }, [sdk]);

  const disconnect = useCallback(async () => {
    try {
      await sdk?.terminate();
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  }, [sdk]);

  useEffect(() => {
    checkIfError(Number(value), chainId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return (
    <>
      {account ? (
        <>
          <TransactionGroup>
            <AppText color={colors.gray_500} style={{ marginTop: "2rem" }}>
              {t("CONNECTED_ACCOUNT")}
            </AppText>
            <ReadInfo text={account} />
          </TransactionGroup>

          <TransactionGroup>
            <AppText color={colors.gray_500}>{t("AMOUNT_TO_DEPOSIT")}</AppText>

            <CurrencyInput
              value={value}
              setValue={(value) => handleChangeValue(value)}
              currency="ETH"
              placeholder="0"
              noSelect
            />
            <InfoLine>
              <WalletIcon />
              <AppText color={colors.gray_600} fontWeight={400} fontSize={12}>
                {t("BALANCE")}
                {` ${convertedToEthBalance} ETH`}
              </AppText>
            </InfoLine>
          </TransactionGroup>
          <RangeInput
            value={value}
            setValue={handleChangeValue}
            currencies={currencies}
            balance={convertedToEthBalance}
            mainCurrency="ETH"
          />

          {hasError && (
            <ModalError>
              <ModalErrorStatus>
                <AppText fontSize={12} color={colors.error_700}>
                  {t("WARNING")}
                </AppText>
              </ModalErrorStatus>
              <ModalErrorInfo>
                <AppText fontSize={12} color={colors.error_700}>
                  {hasError}
                </AppText>
                {chainId !== "0x1" && (
                  <MainButton
                    onClick={disconnect}
                    variant="contained"
                    toFillAndStrokeSvg
                  >
                    <MetaMaskIcon />
                    {t("DISCONNECT_METAMASK_ACCOUNT")}
                  </MainButton>
                )}
              </ModalErrorInfo>
            </ModalError>
          )}
          <TransactionGroup style={{ marginTop: "3rem" }}>
            <ModalButton onClick={sendMetamask} disabled={!!hasError}>
              Deposit Metamask
            </ModalButton>
          </TransactionGroup>
        </>
      ) : (
        <TransactionGroup>
          <MainButton onClick={connect} variant="contained" toFillAndStrokeSvg>
            <MetaMaskIcon />
            {t("CONNECT_METAMASK")}
          </MainButton>
        </TransactionGroup>
      )}
    </>
  );
};

export default DepositMetaMaskTab;
